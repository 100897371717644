<template>
  <base-section
    id="planos"
    class="grey lighten-4 pb-0"
  >
    <v-img
      :src="require('@/assets/Logo.png')"
      class="mx-auto mb-8"
      max-width="250"
    />

    <base-section-heading
      title="Conheça os planos"
      class="px-4"
    >
      Analise o melhor plano para você, e venha viver a Nutrição Moderna.
    </base-section-heading>

    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col cols="12">
          <v-simple-table>
            <thead>
              <tr>
                <th />
                <th
                  class="body-1 font-weight-bold text-center py-4"
                  style="min-width: 130px"
                >
                  Consulta online
                </th>
                <th
                  class="body-1 font-weight-bold text-center"
                  style="min-width: 130px"
                >
                  Consulta presencial*
                </th>
              </tr>
            </thead>

            <tbody class="body-2">
              <tr
                v-for="([name, one, two], i) in features"
                :key="i"
              >
                <td v-html="name" />

                <td class="text-center font-weight-bold">
                  <template v-if="typeof one === 'boolean'">
                    <v-icon :color="one ? 'success' : 'error'">
                      mdi-{{ one ? 'check' : 'close' }}
                    </v-icon>
                  </template>

                  <template v-else-if="one == 'truetrue'">
                    <v-icon>
                      mdi-check-all
                    </v-icon>
                  </template>

                  <template v-else>
                    {{ one }}
                  </template>
                </td>

                <td class="text-center font-weight-bold">
                  <template v-if="typeof two === 'boolean'">
                    <v-icon :color="two ? 'success' : 'error'">
                      mdi-{{ two ? 'check' : 'close' }}
                    </v-icon>
                  </template>

                  <template v-else-if="two == 'truetrue'">
                    <v-icon color="success">
                      mdi-check-all
                    </v-icon>
                  </template>

                  <template v-else>
                    {{ two }}
                  </template>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td
                  colspan="3"
                  class="grey--text grey lighten-3"
                >
                  * Disponível apenas para as cidades catarinenses de Caibi, Chapecó, Palmitos e São Carlos.
                </td>
              </tr>
            </tfoot>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionPlans',

    data: () => ({
      features: [
        ['Consulta agendada', 'Videochamada', 'Presencial'],
        ['Respostas ilimitadas no WhatsApp', true, true],
        ['Avaliação de resultados e reajuste da dieta sempre que necessário', true, true],
        ['Otimização hormonal personalizada', true, true],
        ['Terapia Nutricional Comportamental (auxiliando em comer por ansiedade ou tristeza, episódios compulsivos, autocobrança excessiva, entre outros)', true, true],
        ['Avaliação física (peso, altura, medidas, IMC, etc.)', true, true],
        ['Avaliação de composição corporal (percentual de massa magra, percentual de gordura corporal, etc.)', false, true],
        ['Avaliação de frequência cardíaca e pressão sanguínea', false, true],
        ['Glicose sanguínea pré ou pós prandial (se necessário)', false, true],
      ],
    }),
  }
</script>
